import { Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import AppLogo from '@/common/AppLogo'
import { UiContainer } from '@/ui'

export default function AppFooter() {
  const { palette } = useTheme()

  const { t } = useTranslation()

  return (
    <Stack
      sx={{
        width: '100%',
        mt: 'auto',
      }}
      borderTop={1}
      borderColor={palette.action.active}
    >
      <UiContainer
        sx={{
          py: 6,
          height: 110,
          justifyContent: 'center',
        }}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <AppLogo />
          <Typography variant='buttonMedium' color={palette.text.secondary}>
            {t('app-footer.copyright', {
              year: new Date().getFullYear(),
            })}
          </Typography>
        </Stack>
      </UiContainer>
    </Stack>
  )
}
